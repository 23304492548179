<template>
  <el-card v-loading="isLoading" shadow="always">
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="start">
            <h4>{{ $route.name }}</h4>
          </el-row></el-col
        >
        <el-col :md="16" :sm="16" :xs="14">
          <el-row type="flex" class="gap-x-1" :gutter="8" justify="end">
            <el-button
              type="default"
              v-if="!!selectedItems.length && !ShowProductsList"
              @click="handlePrintSelectedCashierRequests"
            >
              Imprimir {{ selectedItems.length }} pedido(s)
            </el-button>
            <el-button
              type="default"
              v-if="!!selectedItems.length && !ShowProductsList"
              @click="mergeCashierRequests(selectedItems)"
            >
              Criar processo de compra
            </el-button>
            <el-button
              type="default"
              v-if="!!selectedItems.length && ShowProductsList"
              @click="handlePrintSelectedRequestProducts"
            >
              Imprimir romaneios
            </el-button>
            <el-button
              type="default"
              v-if="!!selectedItems.length && ShowProductsList"
              @click="sendToDelivery"
            >
              Enviar para entrega
            </el-button>

            <el-dropdown
              @command="(c) => (requestStatus = c) | fetchRequests()"
              size="medium"
              trigger="click"
            >
              <el-button type="primary" size="medium">
                Filtrar por: {{ RequestStatus[requestStatus] || ""
                }}<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <template #dropdown>
                <el-dropdown-menu>
                  <div
                    v-for="(label, status) in RequestStatus"
                    :key="label + status"
                  >
                    <el-dropdown-item :command="status">
                      <li
                        :class="{
                          'el-dropdown-menu__item': true,
                          'no-padding': true,
                          active: requestStatus === status,
                        }"
                      >
                        {{ label }}
                      </li>
                    </el-dropdown-item>
                  </div>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
            <el-button
              type="primary"
              size="medium"
              @click="() => openProductRequestModal(null)"
              >CADASTRAR</el-button
            >
          </el-row>
        </el-col>
      </el-row>
    </template>
    <el-table
      stripe
      select-on-indeterminate
      :cell-style="() => 'text-align:center;'"
      :data="Data"
      style="width: 100%; z-index: 0"
      @selection-change="handleSelectItems"
    >
      <el-table-column type="selection" :selectable="selectable" width="60" />
      <el-table-column
        :prop="ShowProductsList ? '0' : 'name'"
        label="nome"
      ></el-table-column>
      <el-table-column
        prop="firm.name"
        label="açougue"
        v-if="!ShowProductsList"
      ></el-table-column>
      <el-table-column
        v-if="!ShowProductsList"
        prop="createdBy.name"
        label="responsável"
      ></el-table-column>
      <el-table-column
        v-if="!ShowProductsList"
        label="cadastrado em"
        :formatter="(r) => formatDate(r.created_at)"
      >
      </el-table-column>
      <el-table-column label="urgencia" prop="urgency" v-if="!ShowProductsList">
        <template #default="r">
          <div
            :class="{
              'is-negative': r.row.urgency === 'high',
              'is-medium': r.row.urgency === 'medium',
              'is-positive': r.row.urgency === 'low',
            }"
          >
            {{ Urgency[r.row.urgency] }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="!ShowProductsList"
        prop="products.length"
        label="produtos"
      ></el-table-column>
      <template v-if="ShowProductsList">
        <el-table-column
          v-for="(firm, id) in Firms"
          :key="firm"
          :label="firm"
          :prop="`${id + 1}`"
        ></el-table-column>
      </template>
      <el-table-column
        v-if="ShowProductsList"
        label="TOTAL"
        :prop="`${Firms.length + 1}`"
      />
      <el-table-column label="ações" :min-width="150" v-if="!ShowProductsList">
        <template #default="{ row: productRequest }">
          <el-row type="flex" justify="end">
            <el-button-group>
              <el-popconfirm
                v-if="
                  ['ready'].includes(requestStatus) &&
                  canAny('CanAcceptRequest')
                "
                @confirm="updateStatus(productRequest, 'delivered')"
                title="
              Finalizar entrega?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-check"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="
                  ['created', 'canceled'].includes(requestStatus) &&
                  canAny('CanAcceptRequest')
                "
                @confirm="updateStatus(productRequest, 'accepted')"
                title="
              Aceitar pedido?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-check"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="
                  ['accepted'].includes(requestStatus) &&
                  canAny('CanAcceptRequest')
                "
                @confirm="updateStatus(productRequest, 'ready')"
                title="
              Enviar para entrega?
            "
                ><template #reference>
                  <el-button onlyIcon type="primary" circle>
                    <i class="el-icon-check"></i></el-button></template
              ></el-popconfirm>
              <el-popconfirm
                v-if="
                  ['created'].includes(requestStatus) &&
                  canAny('CanRejectRequest')
                "
                @confirm="updateStatus(productRequest, 'canceled')"
                title="
              Recusar pedido?
            "
                ><template #reference>
                  <el-button onlyIcon type="warning" circle>
                    <i class="el-icon-close"></i></el-button></template
              ></el-popconfirm>

              <el-button
                v-if="['ready'].includes(requestStatus)"
                onlyIcon
                type="primary"
                circle
                @click="printRequest(productRequest)"
              >
                <i class="el-icon-printer"></i
              ></el-button>
              <el-button
                onlyIcon
                type="primary"
                circle
                @click="openProductRequestModal(productRequest)"
              >
                <i class="el-icon-tickets"></i
              ></el-button>
              <el-popconfirm
                v-if="canAny('CanDeleteRequest')"
                @confirm="deleteRequest(productRequest)"
                title="
              Remover pedido de compra?
            "
                ><template #reference>
                  <el-button onlyIcon type="danger" circle>
                    <i class="el-icon-delete"></i></el-button></template
              ></el-popconfirm>
            </el-button-group>
          </el-row>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="currentPage = $event"
      :current-page="currentPage"
      background
      layout="prev, pager, next"
      :page-count="requests?.lastPage"
    >
    </el-pagination>
    <product-request-modal
      :showModal="showProductRequestModal"
      :request="request"
      @close-modal="showProductRequestModal = false"
      @should-update="fetchRequests"
    ></product-request-modal>
  </el-card>

  <el-card
    v-loading="isLoading"
    shadow="always"
    v-if="
      ['accepted', 'ready'].includes(requestStatus) &&
      canAny('CanSeeAllRequests')
    "
  >
    <template #header>
      <el-row type="flex" justify="space-between" align="center">
        <el-col :md="8" :sm="8" :xs="10"
          ><el-row type="flex" justify="space-between">
            <h4>Processos de compra</h4>
          </el-row></el-col
        >
        <div>
          <el-button
            type="default"
            v-if="!!selectedProductPurchases.length"
            @click="handlePrintSelectedProductPurchase"
          >
            Imprimir produtos
          </el-button>
        </div>
      </el-row>
    </template>
    <el-table
      stripe
      :cell-style="() => 'text-align:center;'"
      :data="Purchases"
      style="width: 100%; z-index: 0"
    >
      <el-table-column label="codigo" prop="code" type="expand">
        <template #default="{ row: purchaseProcess }">
          <el-table
            stripe
            select-on-indeterminate
            :data="getAggregatedProducts(purchaseProcess)"
            row-key="uid"
            style="width: 100%; z-index: 0"
            :header-style="
              () => 'text-align:center;padding-left: 2px;padding-right:2px;'
            "
            :cell-style="
              () => 'text-align:center;padding-left: 2px;padding-right:2px;'
            "
            @selection-change="selectedProductPurchases = $event"
          >
            <el-table-column
              type="selection"
              :selectable="selectable"
              width="60"
            />
            <el-table-column
              prop="[0].product.name"
              label="produto"
            ></el-table-column>
            <el-table-column label="Estado do produto">
              <template #default="{ row: products }">
                <el-select
                  v-model="products[0].status"
                  @change="(status) => updateProductStatus(products, status)"
                >
                  <el-option
                    v-for="(label, key) in ProductRequestLabel"
                    :key="key"
                    :value="key"
                    :label="label"
                  ></el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              v-for="firm in getRequestsByFirm(
                purchaseProcess.requests
              )?.keys()"
              :key="firm"
              :label="firm"
            >
              <template #default="{ row: requests }">
                <el-popconfirm
                  v-if="!hasProductsRequestsByFirm(firm, requests)"
                  title="Produto não solicitado para este açougue, adicionar ao pedido?"
                  confirm-button-text="Adicionar ao pedido"
                  cancel-button-text="Cancelar"
                  @confirm="
                    addProductToPurchase(requests, firm, purchaseProcess)
                  "
                >
                  <template #reference>
                    <div>
                      <base-input
                        class="is-disabled"
                        :readonly="true"
                        :model-value="sumProductQuantity(firm, requests)"
                      />
                    </div>
                  </template>
                </el-popconfirm>
                <base-input
                  v-else
                  type="number"
                  :model-value="sumProductQuantity(firm, requests)"
                  v-on:keydown.enter="
                    updateProductQuantity(firm, requests, $event)
                  "
                />
              </template>
            </el-table-column>
            <el-table-column label="total">
              <template #default="{ row: requests }">
                {{ sumProductQuantity(null, requests) }}
              </template>
            </el-table-column>
            <el-table-column
              label="ações"
              :min-width="150"
              v-if="!ShowProductsList"
            >
              <template #default="{ row: productRequest }">
                <el-row type="flex" justify="end">
                  <el-button-group>
                    <el-button
                      onlyIcon
                      type="primary"
                      circle
                      @click="printRequest(productRequest)"
                    >
                      <i class="el-icon-printer"></i
                    ></el-button>
                  </el-button-group>
                </el-row>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column
        label="criado em"
        :formatter="(r) => formatDate(r.created_at)"
      ></el-table-column>
      <el-table-column
        label="total de pedidos"
        prop="requests.length"
      ></el-table-column>
      <el-table-column label="total de produtos">
        <template #default="{ row: purchaseProcess }">
          {{ countBoughtProducts(purchaseProcess?.products) }} /
          {{ purchaseProcess?.products?.length }}
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @update:current-page="purchaseCurrentPage = $event"
      :current-page="purchaseCurrentPage"
      background
      layout="prev, pager, next"
      :page-count="requests?.lastPage"
    >
    </el-pagination>
  </el-card>
</template>

<script>
import ProductRequestModal from "./modals/ProductRequestModal.vue";
import RequestService from "../services/requests";
import SystemService from "../services/system";
import PurchaseProcessService from "../services/purchase_process";
import { generatePDF } from "../services/reports";
import { notifyError, notifySuccess } from "../utils/notifiers";

export default {
  name: "CashierRequests",
  components: {
    ProductRequestModal,
  },
  data: () => ({
    requestStatus: "created",
    showProductRequestModal: false,
    requests: null,
    currentPage: 1,
    purchaseCurrentPage: 1,
    selectedProductPurchases: [],
    request: null,
    purchases: [],
    isLoading: false,
    selectedItems: [],
    dateFormatter: new Intl.DateTimeFormat("pt-BR", {
      dateStyle: "short",
    }),
    currencyFormatter: new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    }),
    numberFormatter: new Intl.NumberFormat("pt-BR", {
      style: "decimal",
    }),
  }),
  mounted() {
    this.fetchRequests();
    this.fetchPurchases();
  },
  computed: {
    Purchases() {
      return this.purchases?.data || [];
    },
    ProductRequestLabel() {
      return {
        pending: "Pendente",
        bought: "Comprado",
        "not available": "Não disponível",
        delivery: "Em entrega",
        delivered: "Entregue",
      };
    },
    Urgency() {
      return {
        low: "Baixa",
        medium: "Média",
        high: "Alta",
      };
    },
    Requests() {
      const self = this;
      return (this.requests || []).map((r) => {
        const filterRequestProducts = self.requestStatus === "ready";

        if (filterRequestProducts) {
          r.products = r.products.filter(
            (product) => product.status === "bought"
          );
        }

        return r;
      });
    },
    Products() {
      return this.Requests.flatMap((req) =>
        req.products.map((ppp) => ({ ...ppp, request: req }))
      );
    },
    RequestProduct() {
      return this.getContent(this.Requests);
    },
    Data() {
      return this.ShowProductsList ? this.RequestProduct : this.Requests;
    },
    Can() {
      return this.$store.state.auth.can;
    },
    Firms() {
      return Array.from(this.getRequestsByFirm().keys());
    },
    ShowProductsList() {
      return this.requestStatus === "ready";
    },
    RequestStatus() {
      return {
        created: "Aberto",
        accepted: "Em processo de compra",
        canceled: "Recusado",
        ready: "Disponível pra entrega",
        delivered: "Entregue",
        ...(this.canAny("CanSeeAllRequests") ? { all: "Tudo" } : {}),
      };
    },
  },
  watch: {
    currentPage() {
      this.fetchRequests();
    },
  },
  methods: {
    async handlePrintSelectedProductPurchase() {
      const self = this;
      const selectedProducts = this.selectedProductPurchases;
      const purchase = this.Purchases.find(
        (purchase) =>
          purchase.id === selectedProducts?.at(0)?.at(0).purchase_process_id
      );
      const firms = this.getRequestsByFirm(purchase?.requests)?.keys();
      const productsByFirm = firms.reduce((total, firm) => {
        const products = selectedProducts
          .map((p) => p.filter((pp) => pp.request.firm.name === firm))
          .filter((p) => !!p.length);
        total.set(firm, products);
        return total;
      }, new Map());

      Array.from(productsByFirm.entries()).forEach(([firm, products]) => {
        if (!products.length) {
          return;
        }

        self.printRequest({
          created_at: new Date(),
          firm: { name: firm },
          products: products.flat().map((p) => ({
            name: p.product.name,
            quantity: p.quantity,
            price: 0,
          })),
        });
      });
    },
    async updateProductQuantity(firm, products, event) {
      const quantity = event.target.value;
      const product = products.find((p) => p.request.firm.name === firm);
      await this.updateProductRequest(product.request, {
        uid: product.uid,
        quantity,
      });
    },
    async addProductToPurchase(pRequests, firm, purchase) {
      const productRequest = pRequests?.at(0);
      const firmRequest = purchase.requests.find((r) => r.firm?.name === firm);

      if (!productRequest?.request) {
        return;
      }
      const product = productRequest.product;
      const createdProduct = await this.createProductRequest(firmRequest, {
        quantity: 0,
        status: productRequest.status,
        purchase_process_id: purchase.id,
        product_id: product?.uid,
      });

      pRequests.push({
        ...createdProduct,
        product: productRequest.product,
        request: firmRequest,
      });
    },
    async updateProductStatus(products, status) {
      for (const product of products) {
        product.status = status;
        this.updateProductRequest(product.request, {
          uid: product.uid,
          status,
        });
      }
    },
    async mergeCashierRequests(requests) {
      const r = requests.map((rr) => {
        return {
          uid: rr.uid,
          products: rr.products.map((p) => p.uid),
        };
      });
      await this.createPurchase({ requests: r });
      this.fetchRequests();
      this.fetchPurchases();
    },
    createPurchase(params) {
      return PurchaseProcessService().create(params);
    },
    async fetchPurchases() {
      const { purchase_process } = await PurchaseProcessService().index({
        pagination: this.purchaseCurrentPage,
      });

      if (purchase_process) {
        this.purchases = purchase_process;
      }
    },
    getAggregatedProducts(purchase) {
      if (!purchase) {
        return [];
      }

      const requestWithProducts = purchase.requests?.map((r) => ({
        ...r,
        products: purchase.products?.filter((p) => p.request_id === r.id),
      }));
      return this.getProductsList(requestWithProducts);
    },
    getProductByName(productName) {
      return this.Products.filter((p) => p.product.name === productName);
    },
    hasProductsRequestsByFirm(firm, products) {
      if (!products?.length) {
        return false;
      }

      return !!products.find((p) => p.request?.firm?.name === firm);
    },
    sumProductQuantity(firm, products) {
      if (!products) {
        return 0;
      }

      return products
        .filter((p) => !firm || p.request?.firm?.name === firm)
        ?.reduce((total, p) => (total += Number(p.quantity)), 0);
    },
    countBoughtProducts(products) {
      if (!products) {
        return 0;
      }

      return products.reduce((total, p) => {
        if (p.status === "bought") {
          total++;
        }
        return total;
      }, 0);
    },
    handlePrintSelectedRequestProducts() {
      const self = this;
      const selectedProducts = this.selectedItems.map((selected) => {
        const [productName] = selected;
        return self.getProductByName(productName);
      });

      const productsByFirm = this.Firms.reduce((total, firm) => {
        const products = selectedProducts
          .map((p) => p.filter((pp) => pp.request.firm.name === firm))
          .filter((p) => !!p.length);
        total.set(firm, products);
        return total;
      }, new Map());

      Array.from(productsByFirm.entries()).forEach(([firm, products]) => {
        if (!products.length) {
          return;
        }

        self.printRequest({
          created_at: new Date(),
          firm: { name: firm },
          products: products.flat().map((p) => ({
            name: p.product.name,
            quantity: p.quantity,
            price: 0,
          })),
        });
      });
    },
    getProductsList(req) {
      const products = req
        .map((r) => r.products.map((p) => ({ ...p, request: r })))
        .flat();
      const productsById = products.reduce((total, pRequest) => {
        const key = pRequest.product.uid;
        if (!total[key]) total[key] = [];

        total[key].push(pRequest);
        return total;
      }, {});

      return Object.values(productsById);
    },
    sumTotalOfProducts(products) {
      return products?.reduce(
        (total, product) => total + Number(product.quantity),
        0
      );
    },
    sortAlphabetically(products) {
      return products?.sort(([a], [b]) =>
        a.product?.name > b.product?.name ? 1 : -1
      );
    },
    getRequestsByFirm(requests) {
      const requestsByFirm = new Map();
      (requests || this.requests)?.forEach((r) => {
        if (requestsByFirm.has(r.firm?.name)) {
          requestsByFirm.get(r.firm?.name).push(r);
          return;
        }

        requestsByFirm.set(r.firm?.name, [r]);
      });
      return requestsByFirm;
    },
    getHeaders() {
      const firmNames = this.getRequestsByFirm().keys();
      return [["PRODUTO", ...firmNames, "QTD TOTAL"]];
    },
    getContent(selectedItems) {
      const requestsByFirm = this.getRequestsByFirm();
      const firmNames = this.Firms;
      const firmQuantities = (product) => {
        const a = firmNames.map(
          (k) =>
            requestsByFirm
              .get(k)
              ?.flatMap((r) => r?.products)
              .reduce(
                (total, r) =>
                  r.product?.uid === product?.uid
                    ? total + Number(r.quantity)
                    : total,
                0
              ) || 0
        );
        return a;
      };
      return this.sortAlphabetically(this.getProductsList(selectedItems)).map(
        (p) => [
          p[0]?.product?.name,
          ...firmQuantities(p[0]?.product),
          this.sumTotalOfProducts(p),
        ]
      );
    },
    handlePrintSelectedCashierRequests() {
      const content = this.getContent(this.selectedItems);

      generatePDF({
        fileName: "Pedidos de Compras",
        content,
        headerMessage: "Relação de Produtos",
        defaultFontSize: 8,
        headers: this.getHeaders(),
      });
    },
    handleSelectItems(items) {
      this.selectedItems = items;
    },
    printRequest(productRequest) {
      if (this.$store.state.user.printer) {
        SystemService()
          .Printers(this.$store.state.user.printer.uid)
          .Job()
          .create({
            layout: "orders",
            print_content: JSON.stringify({
              code: "S/N",
              created_at: productRequest.created_at,
              deliver_at: new Date().toISOString(),
              client: { name: productRequest.firm.name },
              products: productRequest.products.map((p) => ({
                mounted: { product: { name: p?.name } },
                name: p?.name,
                quantity: p?.quantity,
                price: p?.price,
              })),
            }),
          })
          .then(() => {
            this.$message.success("Impressão enviada para a impressora");
          });
      }
    },
    canAny() {
      for (let a in arguments) if (this.Can(arguments[a])) return true;

      return false;
    },
    resetPagination() {
      this.currentPage = 1;
    },
    async sendToDelivery() {
      const self = this;
      const selectedProducts = this.selectedItems
        .map((selected) => {
          const [productName] = selected;
          return self.getProductByName(productName);
        })
        .flat();

      await Promise.all(
        selectedProducts.map((ppp) =>
          self.updateProductRequest(ppp.request, {
            uid: ppp.uid,
            status: "delivery",
          })
        )
      );

      this.fetchRequests();
    },
    updateStatus(request, status) {
      if (request) {
        request.status = status;
        this.updateRequest(request);
      }
    },
    openProductRequestModal(request) {
      this.request = request;
      this.showProductRequestModal = true;
    },
    formatDate(c) {
      if (new Date(`${c}`) != "Invalid Date")
        return this.dateFormatter.format(new Date(c));
      else return "desconhecido";
    },
    async deleteRequest(r) {
      if (!r) return;

      const { error } = await RequestService(r.uid).delete();

      if (error) {
        notifyError("Não foi possível remover esse pedido");
      } else {
        notifySuccess("Pedido removido com sucesso");
      }

      this.fetchRequests();
    },
    async updateProductRequest(request, product) {
      if (!product) return;

      await RequestService(request.uid).Products(product.uid).update(product);
    },
    async createProductRequest(request, p) {
      if (!p || !request) return;

      const { product } = await RequestService(request.uid)
        .Products()
        .create(p);
      return product;
    },
    async updateRequest(r) {
      if (!r) return;

      const { error } = await RequestService(r.uid).update(r);

      if (error) {
        notifyError("Ocorreu um erro ao atualizar o pedido");
      } else {
        notifySuccess("Pedido atualizado com sucesso");
        this.fetchRequests();
      }
    },
    async fetchRequests() {
      this.isLoading = true;

      const requestFilter = {
        exclude_attached: true,
      };

      if (this.requestStatus === "ready") {
        requestFilter.statusIn = ["accepted", "ready"];
      } else if (this.requestStatus !== "all") {
        requestFilter.status = this.requestStatus;
      }

      if (!this.canAny("CanSeeAllRequests")) {
        requestFilter.createdBy = this.$store.state.user.uid;
      }

      const { requests } = await RequestService().index(requestFilter);

      if (requests) {
        this.requests = requests;
      }

      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
.gap-x-1 {
  column-gap: 0.5rem;
}

.is-medium {
  color: #f90;
}

.is-negative {
  color: #f00;
}

.is-positive {
  color: #0f0;
}
</style>
