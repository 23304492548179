import http from "./http";
export default (purchase_processId) => ({
  async index(filters) {
    return http
      .index("purchase_process", filters)
      .then((json) => ({ purchase_process: json }))
      .catch(async (e) => ({ purchase_process: null, error: await e }));
  },
  async get() {
    return http
      .get("purchase_process", purchase_processId)
      .then((json) => ({ purchase_process: json }))
      .catch(async (e) => ({ purchase_process: null, error: await e }));
  },
  async create(data) {
    return http
      .post("purchase_process", data)
      .then((json) => ({ purchase_process: json }))
      .catch(async (e) => ({ purchase_process: null, error: await e }));
  },
  async update(data) {
    return http
      .put("purchase_process", purchase_processId, data)
      .then((json) => ({ purchase_process: json }))
      .catch(async (e) => ({ purchase_process: null, error: await e }));
  },
  async delete() {
    return http
      .delete("purchase_process", purchase_processId)
      .then((json) => ({ purchase_process: json }))
      .catch(async (e) => ({ purchase_process: null, error: await e }));
  },
});
